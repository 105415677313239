import React, { useState } from "react";
import randomData from "./RandomDataGenerator";
import "./Dashboard.css";
import FGraph from "./FGraph";

const Dashboard = () => {
  const [nodes, setNodes] = useState(0);
  const [data, setData] = useState([]);
  const [graphType, setGraphType] = useState("Basic");

  const GROUPS = 12;

  const handleNodeChange = (e) => {
    if (e.target.value > 500) {
      setNodes(500);
    } else if (e.target.value < 0) {
      setNodes(0);
    } else {
      setNodes(e.target.value);
    }
  };

  const handleGraphType = (e) => {
    setGraphType(e.target.value);
    setData([]);
    setNodes(0);
  };

  const handleNodeChangeSubmit = (e) => {
    const count = nodes * 1;
    setData(randomData(count));
  };

  console.log(data);

  return (
    <div className="dashboardContainer">
      <h1>React Force Graph</h1>

      <div className="btnContainer">
        <button
          className={
            graphType === "Basic" ? "graphButton_active" : "graphButton"
          }
          value="Basic"
          onClick={handleGraphType}
        >
          Basic
        </button>

        <button
          className={
            graphType === "Directional" ? "graphButton_active" : "graphButton"
          }
          value="Directional"
          onClick={handleGraphType}
        >
          Directional
        </button>

        <button
          className={
            graphType === "Colored" ? "graphButton_active" : "graphButton"
          }
          value="Colored"
          onClick={handleGraphType}
        >
          Colored
        </button>

        {/* <button
          className={
            graphType === "Basic4" ? "graphButton_active" : "graphButton"
          }
          value="Basic4"
          onClick={handleGraphType}
        >
          Basic4
        </button> */}
      </div>

      <div className="txtContainer">
        <p>Enter the number of nodes you want to see:</p>
        <input
          type="number"
          min="1"
          max="500"
          value={nodes}
          onChange={handleNodeChange}
          placeholder="Enter the number of nodes"
        />
        <button onClick={handleNodeChangeSubmit}>Generate Nodes</button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {graphType === "Basic" ? (
          <FGraph
            data={data}
            message="No Data Available. Please enter a number between 1 and 500."
          />
        ) : (
          <></>
        )}

        {graphType === "Directional" ? (
          <FGraph
            data={data}
            linkDirectionalArrowLength={3.5}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.25}
            message="No Data Available. Please enter a number between 1 and 500."
          />
        ) : (
          <></>
        )}

        {/* {graphType === "Basic4" ? <p>Basic4</p> : <></>} */}

        {graphType === "Colored" ? (
          <FGraph
            data={data}
            nodeAutoColorBy={(d) => d.id % GROUPS}
            linkAutoColorBy={(d) => data.nodes[d.source].id % GROUPS}
            linkWidth={2}
            message="No Data Available. Please enter a number between 1 and 500."
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
