import React from "react";
import { ForceGraph3D } from "react-force-graph";

const FGraph = (props) => {
  const data = props.data;
  const message = props.message;
  return (
    <div className="graphContainer">
      {data !== undefined &&
      data.nodes !== undefined &&
      data.nodes.length > 0 ? (
        <ForceGraph3D {...props} graphData={data} width={1400} height={750} />
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default FGraph;
