import "./App.css";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
// import ClickToFocus from "./components/ClickToFocus";
// import BasicGraph from "./components/BasicGraph";

function App() {
  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/basicgraph" component={BasicGraph} />
          <Route exact path="/clicktofocus" component={ClickToFocus} />
        </Switch>
      </Router> */}
      <Dashboard />
    </div>
  );
}

export default App;
